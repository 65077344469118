import { observable, action, decorate, toJS } from 'mobx'
import { message } from 'antd'
// Request
import { ProjectRequest, ProjectLinkRequest } from '../requests'
import usersStore from './usersStore'
// StoresProjectDetailProjectDetail

class ProjectStore {

  projectList = []
  projectDetail = {}
  projectionList = []
  projectionDetail = []
  listProjectLink = []
  modelList = []
  showDataProject = false;
  treeLoading = true
  queryDefault = null
  isProjectReady = false
  getListGuidHighlight = (projectID, data) => {
    return new Promise((resolve, reject) => {
      ProjectRequest.getListGuidHighlight(projectID, data)
        .then(response => {
          resolve(response?.data)
        })
        .catch(error => {
          reject(error)
        })
    })
  }
  async duplicateProject(projectId, data) {
    return new Promise((resolve, reject) => {
      ProjectRequest.duplicateProject(projectId, data).then((response) => {
        resolve(response.data);
      }).catch(err => {
        this.setLoadingProgress(false)
        console.log(err)
        message.error('an-error-occurred')
        reject(err)
      })
    })
  }

  setShowDataProject = data => {
    this.showDataProject = data
  }
  setTileViews = data => {
    this.tileViews = data
  }

  setPickPosition = value => {
    this.pickPosition = value
  }

  setShowFeatureSettingDrawer = value => {
    this.showFeatureSettingDrawer = value
  }
  setUseAbsoluteOrientation = () => {
    this.useAbsoluteOrientation = !this.useAbsoluteOrientation
  }
  obtAbsoluteDeviceSensor = undefined
  setobtAbsoluteDeviceSensor = (value) => {
    this.obtAbsoluteDeviceSensor = value
  }
  obtRelativeDeviceSensor = undefined
  setobtRelativeDeviceSensor = (value) => {
    this.obtRelativeDeviceSensor = value
  }
  setWebglContextAlpha = value => {
    this.webglContextAlpha = value
  }
  /**
   * Set navagation style 
   * @param {*} x type object
   */
  setNavigationStyles = x => {
    this.navigationStyles = x
  }

  clearNavigationStyles = () => {
    this.navigationStyles = false
  }

  setShowUpdateResourceModel = x => {
    this.showUpdateResourceModel = x;
  }

  setDataAttributeModal = (state) => {
    this.dataAttributeModal = state
  }

  setHideFeatures = x => {
    this.hideFeatures = x;
  }
  setChangeModelStyle = () => {
    this.changeModelStyle = this.changeModelStyle + 1;
  }
  setLoadingAttrData = x => {
    this.loadingAttrData = x;
  }

  setListWMS = (items) => {
    this.listWMS = items
  }

  setLoadedModelTreeData = x => {
    this.loadedModelTreeData = x;
  }

  setModalDrop3DView(status) {
    this.modalDrop3DView = status
  }

  setShowProcessInBackground(status) {
    this.showProcessInBackground = status
  }

  setCheckModalStatus(status) {
    this.checkModalStatus = status
  }

  setSelectedFeature = x => {
    this.selectedFeature = x;
  }

  setSelectedAttrData = x => {
    this.selectedAttrData = x;
  }

  setModelFeatures = x => {
    this.modelFeatures = x;
  }

  setReCenterGPS = b => {
    this.reCenterGPS = b;
  }

  setEnableReCenterGPS = b => {
    this.enableReCenterGPS = b;
  }

  setDropDownMenuCameraSettings = b => {
    this.dropDownMenuCameraSettings = b;
  }

  isLoading = false

  setLoadingProgress = state => {
    this.isLoading = state
  }
  setStartRecodData = b => {
    this.startRecordData = b;
  }
  setArToolTip = t => {
    this.arToolTip = t;
  }
  setRenderRecordDataControl = b => {
    this.renderRecordDataControl = b
  }
  setMasking = b => {
    this.isMasking = b
  }
  setSensorFrequences = b => {
    if (!isNaN(b))
      this.sensorFrequences = Number(b);
  }

  setRecordData = data => {
    Object.assign(this.recordData, data)
  }

  setShowRenderResolutionControl = b => {
    this.renderResolutionControl = b
  }

  setShowARCamera = b => {
    this.isShowARCamera = b
  }

  setCombineBoundingSpheres = b => {
    this.combineBoundingSpheres = b
  }

  setVisitedMode(visitedMode) {
    this.visitedMode = visitedMode
  }

  setProjectDetailError(error) {
    this.projectDetailError = error
  }

  setHiddenTileSelected = x => {
    this.hiddenTileSelected = x
  }

  setShowHiddenTilesList = show => {
    this.showHiddenTilesList = show
  }
  connectNavigation = false

  setConnectNavigation = show => {
    this.connectNavigation = show
  }

  // opencvCalcAngle = id => {
  //   this.openCVData.id = id
  //   return new Promise((resolve, reject) => {
  //     ProjectRequest.getAngleFromArTest(id)
  //       .then(response => {
  //         if (response.data.angle) {
  //           // this.openCVData.angle = response.data.angle
  //           // console.log('response.data', response.data)
  //           resolve(response.data.angle)
  //         } else resolve(999)
  //       })
  //       .catch(error => {
  //         resolve(999)
  //       })
  //   })
  // }
  setOpenCVData = data => {
    Object.assign(this.openCVData, data)
  }
  setAbsoluteHPRData = data => {
    this.absoluteHPRData = data
  }
  set2D = b => {
    this.is2D = b
  }

  setShowFov = show => {
    this.showFov = show
  }

  setShowAmbientOccSetting = show => {
    this.showAmbientOcclusionSetting = show
  }

  setCleanMode = b => {
    this.cleanMode = b
  }

  setZoomToModel = modelId => {
    this.zoomToModel = modelId
  }

  setSendARInfo = p => {
    this.isSendARInfo = p
  }

  setVisibleTilesets = p => {
    this.visibleTilesets = p
  }

  setSkyColor = ort => {
    this.skyColor = ort
  }
  setObtDeviceSensor = ort => {
    this.obtDeviceSensor = ort
  }
  setWorldTerrain = ort => {
    this.WorldTerrain = ort
  }
  setGeoWatch = ort => {
    this.geoWatch = ort
  }

  setCurrentGPS = ort => {
    this.currentGPS = ort
  }

  setClippingMode = mode => {
    this.clippingMode = mode
  }

  setClippingPickDone = value => {
    this.clippingPickDone = value
  }

  setCurrentTile = tile => {
    this.currentTile = tile
  }

  setModelEditPos = (pos, isFromViewer) => {
    // if (!isFromViewer) isFromViewer=false
    if (!pos) {
      this.modelEditPos = false
      return
    }
    Object.assign(this.modelEditPos, pos)
  }

  setShowMap = show => {
    this.showMap = show
  }

  setShowAntialiasing = show => {
    this.showAntialiasing = show
  }

  setProjectDetail(p) {
    this.projectDetail = p
  }

  getAllProjects = () => {
    const getInviteStatus = (data) => {
      switch (data) {
        case 'accepted':
          return 6
        case 'waiting_accept':
          return 5
        case 'rejected':
          return 4
        case 'user_not_exist':
          return 3
        default:
          return 2;
      }
    }
    return new Promise((resolve, reject) => {
      ProjectRequest.getAllProjects()
        .then(response => {
          let _x = response.data.filter(x => x.project)
          let projectListData = [..._x]
          projectListData.map(project => {
            for (var i = 0; i < _x.length; i++) {
              let _project = _x[i]
              if (project?._id !== _project?._id) {
                if (project?.project?._id === _project?.project?._id) {
                  if (getInviteStatus(project.inviteStatus) > getInviteStatus(_project.inviteStatus)) {
                    _x = _x.filter(x => x._id !== _project._id)
                    break
                  } else {
                    _x = _x.filter(x => x._id !== project._id)
                    break
                  }
                }
              }
            }
          })
          this.projectList = _x
          resolve(_x)
        })
        .catch(error => {
          console.log(error)
          reject(error)
        })
    })
  }
  setListProjectLink = data => {
    this.listProjectLink = data;
  }
  async getListProjectLink(projectId) {
    return new Promise((resolve, reject) => {
      ProjectLinkRequest.getListProjectLink(projectId)
        .then(response => {
          let listModel = []
          response.data.map(item => {
            let modelData = item.model3ds.map(model => {
              return {
                ...model,
                link: item.link
              }
            })
            listModel = [...listModel, ...modelData]
          })
          let projectLinks = response.data?.map(item => {
            if (item.visibleData?.length > 0) {
              let visibleData = item.visibleData.find(x => x.userId === usersStore.currentUser?.id);
              if (visibleData) {
                item.isVisibleClip = visibleData.isVisibleClip
                item.isVisible = visibleData.isVisible
                item.isVisible4D = visibleData.isVisible4D
              }
            }
            return item;
          })
          this.setListProjectLink(projectLinks)
          resolve(response)
        })
        .catch(error => {
          console.log(error)
          this.setLoadingProgress(false)
          reject(error)
        })
    })
  }

  getTotalProjects = () => {
    this.setLoadingProgress(true)
    return new Promise((resolve, reject) => {
      ProjectRequest.getTotalProjects()
        .then(response => {
          this.totalProject = response.data
          this.setLoadingProgress(false)
          resolve()
        })
        .catch(error => {
          console.log(error)
          this.setLoadingProgress(false)
          reject()
        })
    })
  }

  getQueryDefault = (projectId) => {
    return new Promise((resolve, reject) => {
      ProjectRequest.getQueryDefault(projectId)
        .then(response => {
          this.queryDefault = response.data?.[0]
          resolve(response.data?.[0]?.dataDefault || [])
        })
        .catch(error => {
          console.log(error)
          reject([])
        })
    })
  }

  updateQueryDefault = async (data, projectId) => {
    if (!projectId) {
      return true
    }
    let newDataUpdate = data.map(item => {
      delete item.listGuid
      delete item.isQuery
      delete item.isActive
      return item
    })
    return new Promise((resolve, reject) => {
      if (this.queryDefault?.id) {
        ProjectRequest.updateQueryDefault(this.queryDefault.id, {
          dataDefault: newDataUpdate
        })
          .then((res) => {
            resolve([])
          })
          .catch(error => {
            console.log(error)
            reject([])
          })
      } else {
        const params = {
          isProjectDefault: projectId,
          dataDefault: newDataUpdate
        }
        ProjectRequest.createQueryDefault(params)
        .then((res) => {
          this.queryDefault = res.data
          resolve([])
        })
        .catch(error => {
          console.log(error)
          reject([])
        })
      }
    })
  }

  setCurrentProjectDetail = (payload) => {
    this.currentProjectDetail = payload;
  }
  setModelList(m) {
    this.modelList = m
  }
  getProjectDetail = async (id, notGetModels) => {
    return ProjectRequest.getDetail(id)
      .then(response => {
        this.setProjectDetail(response.data)
        if (response.data.model3DS && !notGetModels) {
          response.data.model3DS.forEach(m => {
            m.ref = false
          })
          this.setModelList(response.data.model3DS)
        }
        if (response.data.tilesetData) {
          if (response.data.tilesetData.hiddenTile) {
            this.setHiddenTileSelected(response.data.tilesetData.hiddenTile)
          }
        }
      })
      .catch(error => {
        console.log(error);
        this.setProjectDetailError(error)
      })
  }

  createProject(projectData) {

    let param = {
      tilesetData: {
        "coordinateSystem":
        {
          "code": "4326",
          "name": "WGS 84",
          "proj4": "+proj=longlat +datum=WGS84 +no_defs",
          "wkt": "GEOGCS[\"WGS 84\",DATUM[\"WGS_1984\",SPHEROID[\"WGS 84\",6378137,298.257223563,AUTHORITY[\"EPSG\",\"7030\"]],AUTHORITY[\"EPSG\",\"6326\"]],PRIMEM[\"Greenwich\",0,AUTHORITY[\"EPSG\",\"8901\"]],UNIT[\"degree\",0.0174532925199433,AUTHORITY[\"EPSG\",\"9122\"]],AUTHORITY[\"EPSG\",\"4326\"]]"
        }
      },
      metadata: {},
      "name": "test", "coordinateSystem": "4326"
    }
    param = Object.assign(param, projectData)
    this.setLoadingProgress(true)
    return new Promise((resolve, reject) => {
      ProjectRequest.createProject(param)
        .then(response => {
          this.projectList.push(response.data)
          this.setLoadingProgress(false)
          resolve(response)
        })
        .catch(error => {
          this.setLoadingProgress(false)
          reject(error)
        })
    })
  }

  setProjectionDetail = p => {
    this.projectionDetail = p
  }


  setCenterData = data => {
    this.centerData = data
  }

  async getProjectById(projectId) {
    return new Promise((resolve, reject) => {
      ProjectRequest.getDetail(projectId).then((response) => {
        resolve(response);
      }).catch(err => {
        console.log(err);
        reject(err)
      })
    })
  }

  setTreeLoading = (data) => {
    this.treeLoading = data
  }

  setIsProjectReady = (data) => {
    this.isProjectReady = data
  }

}

decorate(ProjectStore, {
  // Observables
  isLoading: observable,
  projectList: observable,
  projectDetail: observable,

  currentAddTab: observable,
  showAddProjectModal: observable,
  showEditLocation: observable,
  showAddResourcePanel: observable,
  displayPanel: observable,
  modelList: observable,
  selectedModel: observable,
  zoomClick: observable,
  showProjectInfoDrawer: observable,
  addressSelectionMode: observable,
  deleteId: observable,

  displayEditLocation: action,
  updateProjectCover: action,
  toggleProjectInfoDrawer: action,
  acceptProjectInvitation: action,
  setShowAddResourcePanel: action,
  setDisplayPanel: action,
  setLoadingProgress: action,
  setProjectDetail: action,
  getProjectDetail: action,
  updateProjectRefPoint: action,
  setProjects: action,
  toggleDrawer: action,
  setCurrentAddTab: action,
  setShowAddProjectModal: action,
  setModelList: action,
  createModel: action,
  setSelectedModel: action,
  getCurrentModel: action,
  setZoomClick: action,

  getAllProjects: action,
  deleteProject: action,
  deleteTileset: action,
  setDeleteId: action,
  clearProjectList: action,
  clearProjectDetail: action,
  setShowEditLocation: action,
  saveProjectSetting: action,

  setAddressSelectionMode: action,
  hideEditLocation: action,

  currentModelId: observable,
  setCurrentModelId: action,

  currentInitLocation: observable,
  setCurrentInitLocation: action,

  showBoudingVolume: observable,
  setShowBoudingVolume: action,

  viewMode: observable,
  setViewMode: action,

  gpsMode: observable,
  setGpsMode: action,

  showMap: observable,
  setShowMap: action,

  showAntialiasing: observable,
  setShowAntialiasing: action,

  showHUD: observable,
  setShowHUD: action,

  showAmbientOcclusion: observable,
  setShowAmbientOcclusion: action,
  setShowInspectorTool: action,
  showInspectorTool: observable,

  newModelId: observable,
  setNewModelId: action,

  modelEditPos: observable,
  setModelEditPos: action,

  currentTile: observable,
  setCurrentTile: action,
  geoWatch: observable,
  setGeoWatch: action,
  obtDeviceSensor: observable,
  obtGPSOrientation: observable,
  setObtGPSOrientation: action,
  setObtDeviceSensor: action,
  clippingMode: observable,
  clippingPickDone: observable,
  setClippingPickDone: action,
  setWorldTerrain: action,
  WorldTerrain: observable,
  setClippingMode: action,
  setCurrentGPS: action,
  currentGPS: observable,

  projectionList: observable,
  setProjectionDetail: action,
  skyColor: observable,
  setSkyColor: action,

  visibleTilesets: observable,
  setVisibleTilesets: action,

  isSendARInfo: observable,
  setSendARInfo: action,
  sendARInfo: action,

  zoomToModel: observable,
  setZoomToModel: action,

  findModelByUrl: action,

  cleanMode: observable,
  setCleanMode: action,

  showFov: observable,
  setShowFov: action,

  is2D: observable,
  set2D: action,
  averageOrientation: observable,

  openCVData: observable,
  setOpenCVData: action,
  // opencvCalcAngle: action,

  connectNavigation: observable,
  setConnectNavigation: action,

  showHiddenTilesList: observable,
  setShowHiddenTilesList: action,

  checkCityModelByUrl: action,
  updateProject: action,

  hiddenTileSelected: observable,
  setHiddenTileSelected: action,

  centerData: observable,

  visitedMode: observable,
  setVisitedMode: action,

  projectDetailError: observable,
  setProjectDetailError: action,

  combineBoundingSpheres: observable,
  setCombineBoundingSpheres: action,

  getHeightWGS84ToLocal: action,
  getHeightLocalToWGS84: action,
  getHeightTwoPointsWGS84ToLocal: action,
  convertProjectPlaneToWGS84Coordinate: action,
  convertWGS84ToProjectPlaneCoordinate: action,
  calculateHeighCenterModelFlowGeoid: action,

  isShowARCamera: observable,
  setShowARCamera: action,


  getTilesetExistCityModel: action,
  setObtGPSElevation: action,
  obtGPSHeight: observable,
  calculateGPSDistance: action,

  renderResolutionControl: observable,
  setShowRenderResolutionControl: action,

  recordData: observable,
  setRecordData: action,

  sampleRange: observable,
  setSampleRange: action,
  smoothFilterType: observable,
  setSmoothFilterType: action,
  sensorFrequences: observable,
  setSensorFrequences: action,

  startRecordData: observable,
  setStartRecodData: action,

  renderRecordDataControl: observable,
  setRenderRecordDataControl: action,

  dropDownMenuCameraSettings: observable,
  setDropDownMenuCameraSettings: action,

  getPublicJson: action,

  updateProjectData: action,

  ifcSetting: observable,
  underGroundSetting: observable,
  setIfcSetting: action,
  setUndergroundSetting: action,
  ambientOccSetting: observable,

  isMasking: observable,
  setMasking: action,

  setArToolTip: action,
  arToolTip: observable,
  enableReCenterGPS: observable,
  setEnableReCenterGPS: action,
  reCenterGPS: observable,
  setReCenterGPS: action,
  setShowAmbientOccSetting: action,
  showAmbientOcclusionSetting: observable,
  // currentEditModel: observable
  modelFeatures: observable,
  setModelFeatures: action,

  showAddFolderModal: observable,
  setShowAddFolderModal: action,

  selectedNode: observable,
  setSelectedNode: action,

  folderEdit: observable,
  setFolderEdit: action,

  sketchEdit: observable,
  setSketchEdit: action,

  showAddResourceModel: observable,
  setShowAddResourceModel: action,

  getModel3DSById: action,

  showEditFileModal: observable,
  setShowEditFileModal: action,

  fileEdit: observable,
  setFileEdit: action,

  setSelectedAttrData: action,
  selectedAttrData: observable,

  setSelectedFeature: action,
  selectedFeature: observable,

  setLoadedModelTreeData: action,
  loadedModelTreeData: observable,

  loadingAttrData: observable,
  setLoadingAttrData: action,

  modalDrop3DView: observable,
  setModalDrop3DView: action,

  showProcessInBackground: observable,
  setShowProcessInBackground: action,

  checkModalStatus: observable,
  setCheckModalStatus: action,

  setHideFeatures: action,
  hideFeatures: observable,

  setIsUpdateViewOrigo: action,
  isUpdateViewOrigo: observable,

  showUpdateResourceModel: observable,
  setShowUpdateResourceModel: action,

  setCurrentProjectDetail: action,
  currentProjectDetail: observable,

  totalProject: observable,
  getTotalProjects: action,
  setNavigationStyles: action,
  navigationStyles: observable,
  clearNavigationStyles: action,

  getProjectById: action,

  setCloseEditModel: action,
  closeEditModel: observable,

  setProgressUploadFile: action,
  progressUploadFile: observable,

  setProgressFileOnS3Ion: action,
  progressFileOnS3Ion: observable,
  showProjectDashBoard: observable,
  setShowProjectDashBoard: action,
  listMoveEndCb: observable,
  listOnTickCb: observable,
  changeModelStyle: observable,
  setChangeModelStyle: action,

  dataAttributeModal: observable,
  setDataAttributeModal: action,
  listWMS: observable,
  setListWMS: action,
  currentUserRoleInProject: observable,
  findProjectUserRole: action,
  clearCurrentUserRoleInProject: action,
  setCurrentUserRoleInProject: action,

  setBeforeVisibleTileset: action,
  beforeVisibleTileset: observable,

  setRestoreUserView3Dmode: action,
  restoreUserView3Dmode: observable,
  webglContextAlpha: observable,
  setWebglContextAlpha: action,
  useAbsoluteOrientation: observable,
  setUseAbsoluteOrientation: action,
  obtAbsoluteDeviceSensor: observable,
  setobtAbsoluteDeviceSensor: action,
  obtRelativeDeviceSensor: observable,
  setobtRelativeDeviceSensor: action,

  update3DMODELS: action,
  updateProjectTreeData: action,

  setAbsoluteHPRData: action,
  absoluteHPRData: observable,
  getMeshModelInfo: action,
  getProjectRefPointOnProjectionPlane: action,
  setPickPosition: action,
  pickPosition: observable,
  showFeatureSettingDrawer: observable,
  setShowFeatureSettingDrawer: action,
  currentCamera: observable,
  setcurrentCamera: action,
  setChangeHeadingRotation: action,
  changeHeadingRotation: observable,

  tileViews: observable,
  setTileViews: action,
  setListProjectLink: action,
  listProjectLink: observable,
  showDataProject: observable,
  setShowDataProject: action,
  setTreeLoading: action,
  treeLoading: observable,
  duplicateProject: action,
  getListGuidHighlight: action,
  getQueryDefault: action,
  queryDefault: observable,
  updateQueryDefault:action,
  isProjectReady: observable,
  setIsProjectReady: action
})

export default new ProjectStore()
