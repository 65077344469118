import React, { useEffect, useState, useContext } from 'react';
import { Form, Input, Button, Select, Switch, DatePicker, Typography, Divider, Spin, Row, Col } from 'antd'
import { SyncOutlined, DownloadOutlined } from "@ant-design/icons";
import { withRouter } from 'react-router-dom'
import { inject, observer } from 'mobx-react'
import { toJS } from 'mobx'
import moment from 'moment'
import { SocketContext } from '../../../socket-context';
import Axios from 'axios';

const { Option } = Select;
const isShowHideObject = true
const defaultData = [
  {
    title: 'AKU',
    isActive: true,
    src: '',
    isQuery: false
  },
  {
    title: 'ARK',
    isActive: true,
    src: '',
    isQuery: false
  },
  {
    title: 'LARK',
    isActive: true,
    src: '',
    isQuery: false
  },
  {
    title: 'RIB',
    isActive: true,
    src: '',
    isQuery: false
  },
  {
    title: 'RIBR',
    isActive: true,
    src: '',
    isQuery: false
  },
  {
    title: 'RIE',
    isActive: true,
    src: '',
    isQuery: false
  },
  {
    title: 'RIEn',
    isActive: true,
    src: '',
    isQuery: false
  },
  {
    title: 'RIG',
    isActive: true,
    src: '',
    isQuery: false
  },
  {
    title: 'RIMEK',
    isActive: true,
    src: '',
    isQuery: false
  },
  {
    title: 'RITr',
    isActive: true,
    src: '',
    isQuery: false
  },
  {
    title: 'RIV',
    isActive: true,
    src: '',
    isQuery: false
  },
  {
    title: 'RIVA',
    isActive: true,
    src: '',
    isQuery: false
  },
  {
    title: 'SAN',
    isActive: true,
    src: '',
    isQuery: false
  },
  {
    title: 'RIMEK',
    isActive: true,
    src: '',
    isQuery: false
  },
  {
    title: 'MALM',
    isActive: true,
    src: '',
    isQuery: false
  },
  {
    title: 'PVM',
    isActive: true,
    src: '',
    isQuery: false
  },
]
const LoginForm = ({ match, authStore, usersStore, projectStore, organizationStore, commonStore, history }) => {
  const socket = useContext(SocketContext);
  const [selectedProject, setSelectedProject] = useState();
  const [form] = Form.useForm();
  const [formadd] = Form.useForm();
  const [listOrganizations, setListOrganizations] = useState([])
  const [isLogin, setisLogin] = useState(false);
  const [projectList, setprojectList] = useState([]);
  const [orgSelected, setOrgSelected] = useState()
  const [time, setTime] = useState(moment());
  const [captureList, setCaptureList] = useState([])
  const [imageLink, setImageLink] = useState()
  const [projectTemplate, setProjectTemplate] = useState()
  const [flag, setFlag] = useState(false)
  const [defaultButton, setDefaultButton] = useState(defaultData)
  const [isLoading, setIsLoading] = useState(false)
  const [isProjectReady, setIsProjectReady] = useState(false)
  const clickButton = async (title) => {
    let newData = await Promise.all(defaultButton.map(async item => {
      if (item.title === title) {
        const isActive = !item.isActive
        item.isActive = isActive
        const res = await getObjectHideShow(item, isActive)
        item.isQuery = true
        item.listGuid = res.listGuid
        item.src = res.src || ''
        let modelGuidGroup = {}
        for (let i = 0; i < res.listGuid.length; i++) {
          const group = res.listGuid[i];
          const modelid = group.modelId
          const guid = group.guid
          if (!modelGuidGroup[modelid]) {
            modelGuidGroup[modelid] = {}
          }
          modelGuidGroup[modelid][guid] = isActive ? 'active' : 'in-active'
        }
        socket.emit("apiNotification", {
          data: { isActive, groupGuid: modelGuidGroup },
          type: 'hide-object',
          apiKey: usersStore.currentUser.apiKey,
          sesstionId: authStore.sesstionId
        });
      }
      return item
    }))

    const dataUpdate = JSON.parse(JSON.stringify(newData));
    projectStore.updateQueryDefault(dataUpdate, selectedProject)
    setIsLoading(false)
    setDefaultButton(newData)
  }
  const getObjectHideShow = async (data) => {
    setIsLoading(true)
    let listGuid = []
    let newSrc = null
    if (data.isQuery) {
      listGuid = data.listGuid
    } else {
      if (data.src) {
        const response = await Axios.get(data.src)
        const res = response.data
        listGuid = res.listGuid
      }
      const _payload = { attribute: 'Disiplin', condition: '==', value: data.title }
      await projectStore
        .getListGuidHighlight(selectedProject, _payload).then(res => {
          listGuid = res.listGuid
          newSrc = res.src
        })
        .catch(err => {})
    }
    return { listGuid, src: newSrc }
  }


  const handleAuthenticated = async function (msg) {
    const { type, data } = msg
  }

  const onReadCamera = function () {
    socket.emit("request-data", {
      apiKey: usersStore.currentUser.apiKey,
      type: 'request-camera',
      sesstionId: authStore.sesstionId
    });
    socket.emit("request-data", {
      apiKey: usersStore.currentUser.apiKey,
      type: 'request-time',
      sesstionId: authStore.sesstionId
    });
  }

  const onReadDataVisible = function () {
    socket.emit("apiNotification", {
      data: { projectId: selectedProject, userId: usersStore.currentUser.id },
      type: 'read-visible',
      apiKey: usersStore.currentUser.apiKey,
      sesstionId: authStore.sesstionId
    });

  }

  const onGetDataSources = async function () {
    if (selectedProject) {
      projectStore.setLoadingProgress(true)
      await projectStore.getProjectDetail(selectedProject)
      await projectStore.getListProjectLink(selectedProject)
      await onReadDataVisible()
    }
  }

  const handleOpenProject = async (p) => {
    setSelectedProject(p)
    projectStore.setIsProjectReady(false)
    setIsProjectReady(false)
    projectStore.setLoadingProgress(true)
    socket.emit("apiNotification", {
      data: { id: p },
      type: 'goto-project',
      apiKey: usersStore.currentUser.apiKey,
      sesstionId: authStore.sesstionId
    });
    const defaultData = await projectStore.getQueryDefault(p)
    if (defaultData.length > 0) {
      let newData = defaultData.map(item => {
        item.isQuery = false
        item.isActive = true
        return item
      })
      setDefaultButton(newData)
    }
  }
  function onChangeShadow(checked) {
    socket.emit("apiNotification", {
      data: checked,
      type: 'toggle-shadow',
      apiKey: usersStore.currentUser.apiKey,
      sesstionId: authStore.sesstionId
    });
  }

  const setupApiKeyConnect = async function () {
    if (!usersStore.currentUser.id && commonStore.checkToken()) {
      await usersStore.getCurrentUser()
    }
    if (usersStore.currentUser && usersStore.currentUser.id && usersStore.currentUser.apiKey) {
      if (!usersStore.joinAPIRoom) {
        usersStore.setjoinAPIRoom()
        organizationStore.getOrganizationUserBelong().then(res => {
          let _orgList = res.map(elm => {
            let result = []
            let _numberProject = 0
            let _storageQuota = 0
            let _totalLicenses = elm?.licenses
            if (_totalLicenses && _totalLicenses.length > 0) {
              for (let i = 0; i < _totalLicenses.length; i++) {
                let startLicenseDate = _totalLicenses[i].activated ? _totalLicenses[i].activated : _totalLicenses[i].createdAt
                let endLicenseDate = _totalLicenses[i].expiration ? _totalLicenses[i].expiration : moment(_totalLicenses[i].createdAt, 'YYYY-MM-DDTHH:mm:ss.SSSSZ').add(_totalLicenses[i]?.timeLimit || 0, 'days')
                if (!(_totalLicenses[i].isActive) || !(moment(Date.now()).isBetween(startLicenseDate, endLicenseDate))) continue
                result.push(_totalLicenses[i])
              }
            }
            if (result.length > 0) {
              result.map(item => {
                _numberProject += item.numberProject || 0
                _storageQuota += item.storageQuota || 0
              })
            }
            _storageQuota = _storageQuota !== 'UNLIMITTED' ? parseFloat(_storageQuota.toFixed(3)) : _storageQuota
            return {
              id: elm.id,
              name: elm.name + " " + '(' + `${_storageQuota} GB)`,
              user: elm.user,
              numberProject: _numberProject,
              projects: elm.projects.length
            }
          })
          let _cp = _orgList.filter(_cp => _cp.projects < _cp.numberProject)
          setListOrganizations(_cp)
          projectStore.setLoadingProgress(false)
        })
          .catch(err => {
            projectStore.setLoadingProgress(false)
            console.log(err)
            if (err.status === 401) {
              return history.push("/auth/login")
            }
          })
      }
    }

  }
  useEffect(() => {
    socket.on("onRequestData", msg => {
      const { data, type, sesstionId } = msg
      if (type === 'open-project') {
        projectStore.setIsProjectReady(true)
        setIsProjectReady(true)
        projectStore.setLoadingProgress(false)
      }
      if (type === "read-visible") {
        let hiddenData = data?.modelHiden || []
        let visible4DModels = data?.visible4DModels || []
        let visibleClipModels = data?.visibleClipModels || []
        const { treeData } = projectStore.projectDetail
        const modelList = projectStore.modelList.length ? projectStore.modelList : projectStore.projectDetail.model3DS
        let tempModel3D = toJS(modelList).map(item => {
          return { ...item, modelId: item.id, isVisible: true, isVisible4D: true, isVisibleClip: true, }
        })

        if ((hiddenData && hiddenData.length > 0) || (visible4DModels && visible4DModels.length > 0) || (visibleClipModels && visibleClipModels.length > 0)) {
          const _visibletileset = tempModel3D.map(function (newData) {
            let isExist = hiddenData.find(c => c.modelId === newData.modelId)
            let isExist4D = visible4DModels.find(c => c.type4D === 'model' && c.modelId === newData.modelId)
            let isExistClip = visibleClipModels.find(c => c.modelId === newData.modelId)
            if (isExist) {
              newData.isVisible = false
              newData.isTempHidden = isExist.isTempHidden
            }
            if (isExist4D) {
              newData.isVisible4D = false
            }
            if (isExistClip) {
              newData.isVisibleClip = false
            }
            return newData
          });
          projectStore.setModelList(_visibletileset)
        } else {
          projectStore.setModelList(tempModel3D)
        }

        projectStore.setShowDataProject(true)
        projectStore.setTreeLoading(false)
        projectStore.setLoadingProgress(false)
      }

    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [socket])
  const onScreenShot = function () {
    socket.emit("request-data", {
      apiKey: usersStore.currentUser.apiKey,
      data: { capture: true },
      type: 'request-screenShot',
      sesstionId: authStore.sesstionId
    });
  }
  const authType = match.params.authType

  const CustomForm = props => {
    const handleSubmit = values => {
      authStore.userLogin(values.username, values.password, values.publicKey, values.remember, history).then(function (data) {
        setupApiKeyConnect()
        setisLogin(true)
        projectStore.getAllProjects().then(x => setprojectList(x))
        socket.on("authenticated", msg => {
          handleAuthenticated(msg)
        })
        socket.on("onRequestData", msg => {
          const { data, type, sesstionId } = msg
          if (type === 'read-camera') {
            if (data && data.position && data.orientation) {

              form.setFieldsValue({ lat: data.position.lat * 180 / Math.PI });
              form.setFieldsValue({ lng: data.position.lng * 180 / Math.PI });
              form.setFieldsValue({ elevation: data.position.elevation });
              form.setFieldsValue({ heading: data.orientation.heading });
              form.setFieldsValue({ pitch: data.orientation.pitch });
              form.setFieldsValue({ roll: data.orientation.roll });
              form.setFieldsValue({ fov: data.fov.fov });
            }
          }
          if (type === 'read-time') {
            if (data) {

              const cTime = moment(data)
              if (cTime.isValid())
                setTime(cTime)
            }
          }
          if (type === 'read-captureList') {
            if (data) {
              setCaptureList(data.captureList)
            }
          }
        })

        socket.emit("authenticate", {
          username: values.username,
          password: values.password,
          publicKey: values.publicKey,
          sesstionId: authStore.sesstionId
        });
      })

    }
    const validateEmptyString = (rule, value, callback) => {
      if (value && value.trim() === '') {
        return Promise.reject("Must contain a letter")
      } else {
        return Promise.resolve()
      }
    }
    const handleSendCam = values => {
      form.validateFields().then(values => {

        const camdata = {
          "position": {
            "lat": 27.93673,
            "lng": 64.26739,
            "elevation": 55.83139
          },
          // "direction": {
          //   "x": -0.3974566481218389,
          //   "y": -0.914086963592685,
          //   "z": -0.08045642207840209
          // },
          // "up": {
          //   "x": -0.3974566481218389,
          //   "y": -0.914086963592685,
          //   "z": -0.08045642207840209
          // },
          "orientation": { "heading": 0, "pitch": 0, "roll": 0 },
          "fov": { "fov": 0 }
        }
        //debugger
        if (values.lat)
          camdata.position.lat = values.lat
        if (values.lng)
          camdata.position.lng = values.lng
        if (values.elevation)
          camdata.position.elevation = values.elevation
        if (values.heading)
          camdata.orientation.heading = values.heading
        if (values.pitch)
          camdata.orientation.pitch = values.pitch
        if (values.roll)
          camdata.orientation.roll = values.roll
        if (values.fov)
          camdata.fov.fov = values.fov

        socket.emit("apiNotification", {
          data: camdata,
          type: 'goto-view',
          apiKey: usersStore.currentUser.apiKey,
          sesstionId: authStore.sesstionId
        });
      })
    }
    const onGetCaptureList = () => {
      socket.emit("apiNotification", {
        type: 'getSavedViews',
        apiKey: usersStore.currentUser.apiKey,
        sesstionId: authStore.sesstionId
      });
    }
    const onCreate = () => {
      formadd.validateFields().then(values => {
        if (values.template) {
          const payload = {
            desc: values?.desc,
            organization: values?.organization,
            name: values?.name,
            project: values?.template
          }
          projectStore.setLoadingProgress(true)
          projectStore.duplicateProject(payload.project, payload)
            .then(res => {
              projectStore.getAllProjects().then(x => setprojectList(x))
              formadd.resetFields()
              projectStore.setLoadingProgress(false)
              socket.emit("apiNotification", {
                data: { id: res.id },
                type: 'goto-project',
                apiKey: usersStore.currentUser.apiKey,
                sesstionId: authStore.sesstionId
              });
            })
        } else {
          var v = Object.assign(values, {
            coordinateSystem: projectStore.projectionDetail.length > 0 ? projectStore.projectionDetail[0].code : '4326'
          })
          v.tilesetData = {
            coordinateSystem:
              projectStore.projectionDetail.length > 0 ?
                {
                  code: projectStore.projectionDetail[0].code,
                  name: projectStore.projectionDetail[0].name,
                  proj4: projectStore.projectionDetail[0].proj4,
                  wkt: projectStore.projectionDetail[0].wkt
                }
                :
                false
          }
          projectStore.createProject(v)
            .then(res => {
              projectStore.getAllProjects().then(x => setprojectList(x))
              formadd.resetFields()
              socket.emit("apiNotification", {
                data: { id: res.data.project.id },
                type: 'goto-project',
                apiKey: usersStore.currentUser.apiKey,
                sesstionId: authStore.sesstionId
              });
            })
        }
      });
    }
    const handleSetTime = (value) => {
      setTime(value)
      socket.emit("apiNotification", {
        data: value,
        type: 'set-time',
        apiKey: usersStore.currentUser.apiKey,
        sesstionId: authStore.sesstionId
      });
    }

    const handleChangePublicKey = (e) => {
      let value = e.target.value
      authStore.setPublicKey(value)
    }
    return (
      <>
        <React.Fragment>
          {authStore.isLogin ?
            isShowHideObject ? <div>

              <Spin spinning={isLoading}>
                <Select
                  style={{ width: '100%', marginTop: 20 }}
                  onChange={v => handleOpenProject(v)}
                  value={selectedProject}
                  showSearch
                  placeholder="Select project"
                  filterOption={(input, option) => option?.children?.toLowerCase()?.includes(input?.toLowerCase())}
                >
                  {
                    projectList.map(proj =>
                      <Option value={proj.project._id} key={proj._id}>{proj.project.name}</Option>
                    )
                  }
                </Select>
                <div style={{ padding: 0 }}>
                  <div style={{ fontSize: 20 }}>Query API Demo</div>
                  <div>Click on disciplines and see them turn on / off xD TWIN window: </div>
                </div>
                <Row style={{ display: 'flex', justifyContent: 'space-between' }}>
                  {
                    defaultButton.map(item => {
                      return <Col>
                        <Button type={item.isActive ? "primary" : ''} disabled={!isProjectReady}
                          style={item.isActive ? { width: 60, margin: '5px 0', display: 'flex', justifyContent: 'center' } :
                            { width: 60, margin: '5px 0', backgroundColor: '#f2652480', display: 'flex', justifyContent: 'center' }}
                          onClick={() => clickButton(item.title)}>{item.title}
                        </Button>
                      </Col>
                    })
                  }
                </Row>
              </Spin>
            </div> : <React.Fragment>
              <Select
                style={{ width: '100%', marginTop: 20 }}
                onChange={v => handleOpenProject(v)}
                value={selectedProject}
                placeholder="Select project"
              >
                {
                  projectList.map(proj =>
                    <Option value={proj.project._id} key={proj._id}>{proj.project.name}</Option>
                  )
                }
              </Select>
              <Button block size={'large'} onClick={onGetDataSources} type={'primary'} htmlType={'default'} style={{ marginTop: 20 }} >
                Show data project
              </Button>
              <Form form={formadd} name="add-project" layout="vertical" style={{ marginTop: 20 }}>
                <Form.Item label="Organization" name="organization" rules={[
                  {
                    required: true,
                    message: 'Please select an organization of project!',
                  },
                ]}
                >
                  <Select
                    placeholder="Please select an organization..."
                    allowClear={true}
                    onChange={v => setOrgSelected(v)}
                  >
                    {
                      listOrganizations ? listOrganizations.map((v, i) => {
                        return (
                          <Option key={v.id} value={v.id} style={{ textTransform: 'uppercase' }} >{v.name}</Option>
                        )
                      }) : <Option>{'No organization found'}</Option>
                    }
                  </Select>
                </Form.Item>
                {
                  flag && orgSelected && (
                    <Form.Item label='Project template' name="template" rules={[
                      {
                        required: false,
                        message: 'Please select an project template',
                      },
                    ]}
                    >
                      <Select
                        showSearch
                        placeholder='Please select an template'
                        allowClear={true}
                        onChange={v => setProjectTemplate(v)}
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                          option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                      >
                        {
                          organizationStore.organizationTemplates ? organizationStore.organizationTemplates.map((v, i) => {
                            return (
                              <Option key={v.id} value={v.id} >{v.name}</Option>
                            )
                          }) : <Option>{'No template found'}</Option>
                        }
                      </Select>
                    </Form.Item>
                  )
                }
                <Form.Item label="Title" name="name" rules={[
                  {
                    required: true,
                    message: 'Please input the title of project!',
                  },
                  { validator: validateEmptyString },
                ]}>
                  <Input
                    placeholder={'Input project title'} />
                </Form.Item>
                <Button
                  key="submit"
                  type="primary"
                  onClick={onCreate}>
                  Create New Project
                </Button>
              </Form>
              <br />
              <br />
              <div className={'setting-row'}>
                <span>Shadow</span>
                <br />
                <Switch
                  checkedChildren="ON"
                  unCheckedChildren="OFF"
                  size={'small'}
                  defaultChecked={false}
                  onChange={onChangeShadow}
                />
              </div>
              <div className="timeSearch">
                <Typography className="timeSearch-title">Current time: </Typography>
                <div className="timeSearch-datePicker">
                  <DatePicker inputReadOnly={true} showTime placeholder="Select Time"
                    value={time}
                    onChange={handleSetTime}
                    onOk={handleSetTime}
                    style={{ minWidth: 0 }}
                    allowClear={false} />
                </div>
              </div>

              <Form
                form={form}
                name="cam-form"
                layout="vertical"
                className={`auth-form auth-form-${authType}`}
              >
                <Form.Item label={'Lat'} name="lat">
                  <Input style={{ marginTop: 10 }} placeholder="Lat" />
                </Form.Item>
                <Form.Item label={'Long'} name="lng">
                  <Input style={{ marginTop: 10 }} placeholder="Long" />
                </Form.Item>
                <Form.Item label={'Elevation'} name="elevation">
                  <Input style={{ marginTop: 10 }} placeholder="Elevation" />
                </Form.Item>
                <Form.Item label={'Yaw'} name="heading">
                  <Input style={{ marginTop: 10 }} placeholder="Yaw" />
                </Form.Item>
                <Form.Item label={'Pitch'} name="pitch">
                  <Input style={{ marginTop: 10 }} placeholder="Pitch" />
                </Form.Item>
                <Form.Item label={'Roll'} name="roll">
                  <Input style={{ marginTop: 10 }} placeholder="Roll" />
                </Form.Item>
                <Form.Item label={'FOV'} name="fov">
                  <Input style={{ marginTop: 10 }} placeholder="FOV" />
                </Form.Item>
                <Button block size={'large'} type={'primary'} htmlType={'default'} onClick={handleSendCam}>
                  SendCamera
                </Button>
                <Button block size={'large'} onClick={onReadCamera} type={'primary'} htmlType={'default'} style={{ marginTop: 20 }}>
                  ReadCamera
                </Button>
                <Divider />
                <Form.Item label={'Capture list'} name="captureList">
                  <Input.Group compact>
                    <Select allowClear style={{ width: "79%" }} value={imageLink} onChange={(v) => setImageLink(v)}>
                      {
                        captureList?.length > 0 && captureList.map((el, index) => <Option key={el._id} value={el?.image?.url}>{el?.name}</Option>)
                      }
                    </Select>
                    <Button icon={<SyncOutlined />} type='primary' onClick={onGetCaptureList} />
                    <a href={imageLink}><Button icon={<DownloadOutlined />} type='primary' disabled={imageLink ? false : true} /></a>
                  </Input.Group>
                </Form.Item>
                <Button block size={'large'} onClick={onScreenShot} type={'primary'} htmlType={'default'}>
                  ScreenShot
                </Button>
              </Form>
            </React.Fragment>
            :
            <Form
              name="login-form"
              layout="vertical"
              className={`auth-form auth-form-${authType}`}
              onFinish={handleSubmit}
            >
              <Form.Item onChange={handleChangePublicKey} label={'Public key'} name="publicKey" rules={[
                {
                  required: true,
                  message: 'Please input your public key',
                },
              ]}>
                <Input placeholder={'Your public key'} />
              </Form.Item>
              <Form.Item label={'Username'} name="username" rules={[
                {
                  required: true,
                  message: 'Please input your username',
                },
              ]}>
                <Input placeholder={'Your username'} />
              </Form.Item>
              <Form.Item label={'Password'} name="password" rules={[
                {
                  required: true,
                  message: 'Please input your password',
                },
              ]}>
                <Input type='password' placeholder={'Your password'} />
              </Form.Item>
              <Button block size={'large'} type={'primary'} htmlType={'submit'}>
                Login
              </Button>
            </Form>
          }
        </React.Fragment >
      </>

    )
  }

  return <CustomForm >

  </CustomForm>

}

export default withRouter(inject('authStore', 'usersStore', 'projectStore', 'organizationStore', 'commonStore')(observer(LoginForm)))