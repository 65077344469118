import React, { useEffect } from 'react'
// Config
import { cesiumToken } from './config'
import { Ion } from 'cesium'
// Pages
import AuthPage from './components/pages/AuthPage'

// Routing
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import { createBrowserHistory } from 'history'

// MobX
import { Provider } from 'mobx-react'
import commonStore from './stores/commonStore'
import projectStore from './stores/projectStore'
import authStore from './stores/authStore'
import usersStore from './stores/usersStore'
import organizationStore from './stores/organizationStore'

// Styles
import './App.less'


import {SocketContext, socket} from './socket-context'

const stores = {
  commonStore,
  projectStore,
  authStore,
  usersStore,
  organizationStore,
}

const history = createBrowserHistory()


const App = () => {
  Ion.defaultAccessToken = cesiumToken

  useEffect(() => {
    usersStore.setCurrentUser(history)
  }, [])

  return (
    <SocketContext.Provider value={socket}>
      <Provider {...stores}>
        <Router history={history}>
          <Switch>
            <Route exact path="/" component={AuthPage} />
           
            <Route exact path="/auth/:authType" component={AuthPage} />

          </Switch>
        </Router>
      
      </Provider>
    </SocketContext.Provider>
  )
}

export default App
